import type { StoreActionApi } from 'react-sweet-state';
import { createStore, createHook, createActionsHook, createStateHook } from 'react-sweet-state';

import type { UnreadCommentQuery_comments_nodes as UnreadCommentNode } from '../__types__/UnreadCommentQuery';
import type {
	ThreadKeyToCommentIdMap,
	UnreadComment,
	UnreadCommentsState,
} from '../types/unreadCommentsTypes';

const initialState: UnreadCommentsState = {
	unreadCommentsListState: [], // list of unread parent comment and reply IDs and their marker refs
	readCommentsListState: new Set(), // list of read parent comment and reply IDs
	shouldUpdateUnreadComments: false,
	shouldUpdateUnreadCommentsOnFirstRender: true,
	threadKeyToCommentIdMap: {}, // maps each marker ref to a list of comments, with the first comment as the parent comment
	allComments: [], // list of all parent comment and reply information, both read and unread
	isUnreadQueryLoading: false,
};

const actions = {
	updateUnreadCommentsListState:
		(
			transform: (
				prevUnreadCommentsListState: UnreadComment[],
				threadKeyToCommentIdMap: ThreadKeyToCommentIdMap,
			) => UnreadComment[],
		) =>
		({ setState, getState }: StoreActionApi<UnreadCommentsState>) => {
			const { unreadCommentsListState, threadKeyToCommentIdMap } = getState();
			setState({
				unreadCommentsListState: transform(unreadCommentsListState, threadKeyToCommentIdMap),
			});
		},
	updateReadCommentsListState:
		(transform: (prevReadCommentsListState: Set<string>) => Set<string>) =>
		({ setState, getState }: StoreActionApi<UnreadCommentsState>) => {
			const { readCommentsListState } = getState();
			setState({
				readCommentsListState: transform(readCommentsListState),
			});
		},
	updateUnreadComments:
		(shouldUpdateUnreadComments: boolean) =>
		({ setState }: StoreActionApi<UnreadCommentsState>) => {
			setState({ shouldUpdateUnreadComments });
		},
	updateUnreadCommentsOnFirstRender:
		(shouldUpdateUnreadCommentsOnFirstRender: boolean) =>
		({ setState }: StoreActionApi<UnreadCommentsState>) => {
			setState({ shouldUpdateUnreadCommentsOnFirstRender });
		},
	updateThreadKeyToCommentIdMap:
		(transform: (threadKeyToCommentIdMap: ThreadKeyToCommentIdMap) => ThreadKeyToCommentIdMap) =>
		({ setState, getState }: StoreActionApi<UnreadCommentsState>) => {
			const { threadKeyToCommentIdMap } = getState();
			setState({
				threadKeyToCommentIdMap: transform(threadKeyToCommentIdMap),
			});
		},
	updateAllComments:
		(allComments: UnreadCommentNode[]) =>
		({ setState }: StoreActionApi<UnreadCommentsState>) => {
			setState({ allComments });
		},

	setIsUnreadQueryLoading:
		(loading: boolean) =>
		({ setState }: StoreActionApi<UnreadCommentsState>) => {
			setState({ isUnreadQueryLoading: loading });
		},
};

const UnreadCommentsStore = createStore({
	initialState,
	actions,
	name: 'UnreadCommentsStore',
});

export const useUnreadComments = createHook(UnreadCommentsStore);
export const useUnreadCommentsActions = createActionsHook(UnreadCommentsStore);
export const useUnreadCommentsState = createStateHook(UnreadCommentsStore);
