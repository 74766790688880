export enum PageMode {
	EDIT = 'edit',
	VIEW = 'view',
	LIVE = 'live',
}

export enum ContentStatus {
	CURRENT = 'current',
	DRAFT = 'draft',
	ARCHIVED = 'archived',
}

export const DRAFT_STATUS = [ContentStatus.DRAFT];

export const CURRENT_STATUS = [ContentStatus.CURRENT];
